const MobileDetect = require("mobile-detect");

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('./serviceWorker.js').then(function(registration) {
            //no-op
        }, function(err) {
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}

function beforeInstallPrompt(event) {
    const os = new MobileDetect(navigator.userAgent).os();
    if(os === "AndroidOS") {
        event.prompt();
    }
}

window.addEventListener('beforeinstallprompt', this.beforeInstallprompt);
